<template>
  <item-list-view
    :api="api"
    :headers="headers"
    id-key="id"

    :allowed-search="true"
    :allowed-create="false"
    :allowed-remove="false"
    update-route="Photo"
  >

    <template v-slot:item.image="{ item }">
      <v-img :src="item.thumbnailUrl"
         max-height="200"
         max-width="200"
     contain />
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | dateTime }} 
    </template>
    <template v-slot:item.selectImage="{ item }"> 
      <v-btn 
        color="primary"
        @click="onSelect(item.originalUrl)"
      >Выбрать</v-btn>
    </template>
  </item-list-view>
</template>

<script>
import config from '../../config'
import api from '../../services/photos'
import ItemListView from '../../components/ItemListView'

export default {
  components: { ItemListView },
  data: () => ({
    api: api,
    headers: [
      { text: 'Изображение', value: 'image' },
      { text: 'Дата загрузки', value: 'createdAt' },
      { text: '', value: 'selectImage' }
    ],
    options: {
      url: config.photoUploadUrl,
      acceptedFiles: 'image/*',
      maxFilesize: 15,
    }
  }),
  methods: {
    onSelect(url) {
      this.$emit('imageSelect', url)
    }
  }
}
</script>
